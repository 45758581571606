// extracted by mini-css-extract-plugin
export var contentTeaserText = "styles-module--contentTeaserText--msHBs";
export var image = "styles-module--image--r5NiW";
export var introEnter = "styles-module--introEnter--dntvo";
export var introEnterActive = "styles-module--introEnterActive--TChvC";
export var introExit = "styles-module--introExit--o5Gxg";
export var introExitActive = "styles-module--introExitActive--nOW2o";
export var questionsEnter = "styles-module--questionsEnter--N8iQc";
export var questionsEnterActive = "styles-module--questionsEnterActive--+GfqB";
export var questionsExit = "styles-module--questionsExit--PIY+g";
export var questionsExitActive = "styles-module--questionsExitActive--dvg6H";
export var resultEnter = "styles-module--resultEnter--ABa8P";
export var resultEnterActive = "styles-module--resultEnterActive--v5lUQ";
export var resultExit = "styles-module--resultExit--+5km2";
export var resultExitActive = "styles-module--resultExitActive--qlYok";
export var textAbove = "styles-module--textAbove---IUQx";
export var wrapper = "styles-module--wrapper--TvVdS";