import React from 'react';
import c from 'classnames';
import * as styles from './styles.module.scss';

type BadgeProps = {
  headline: string;
  position?: 'top' | 'middle' | 'bottom' | 'left' | 'bottomMid';
  firstHeadline: string;
  secondHeadline?: string;
  size?: 'small' | 'big';
  isHeader?: boolean;
};

const Badge = ({ headline, firstHeadline, secondHeadline, position, size = 'small', isHeader = false }: BadgeProps) => {
  return (
    <div
      className={c({
        [styles.top]: position === 'top',
        [styles.middle]: position === 'middle',
        [styles.left]: position === 'left',
        [styles.bottom]: position === 'bottom',
        [styles.bottomMid]: position === 'bottomMid',
        [styles.big]: size === 'big',
      })}
    >
      {headline && <p className={styles.headline}>{headline}</p>}
      {isHeader ? (
        <div>
          {firstHeadline && <p className={styles.subline}>{firstHeadline}</p>}
          {secondHeadline && <p className={styles.subline}>{secondHeadline}</p>}
        </div>
      ) : (
        <>
          {firstHeadline && <p className={styles.subline}>{firstHeadline}</p>}
          {secondHeadline && <p className={styles.subline}>{secondHeadline}</p>}
        </>
      )}
    </div>
  );
};

export default Badge;
