import React, { useContext } from 'react';
import classNames from 'classnames';
import * as styles from './styles.module.scss';
import { FamilyCheckContext } from '../../../providers';

type Props = {
  children?: React.ReactNode;
  className?: string;
  activeTab?: number;
};

const Card: React.FC<Props> = ({ children, className = '', activeTab }) => {
  const { currentQuestion } = useContext(FamilyCheckContext);
  return (
    <div className={classNames(styles.wrapper, className)}>
      {activeTab && (
        <>
          <div
            className={classNames(styles.wrapper, styles.card2, {
              [styles.isHidden]: activeTab === 2 || activeTab === 3,
            })}
          />
          <div
            className={classNames(styles.wrapper, styles.aniCard, {
              [styles.animate]:
                (currentQuestion === 3 && activeTab === 2) || (currentQuestion === 6 && activeTab === 3),
            })}
          />
          <div className={classNames(styles.wrapper, styles.card1, { [styles.isHidden]: activeTab === 3 })} />
        </>
      )}
      {children}
    </div>
  );
};

export default Card;
