import React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import * as styles from './styles.module.scss';

type Props = {
  checked?: boolean;
  className?: string;
  label?: string;
  name?: string;
  onClick?: React.ChangeEventHandler<HTMLInputElement>;
};

const Checkbox: React.FC<Props> = ({ label = '', className = '', name = '', checked = false, onClick = () => {} }) => {
  const id = `${label}_${uuid()}`;
  return (
    <div className={classNames(styles.checkboxGroup, className)}>
      <input
        type="checkbox"
        id={id}
        className={styles.customCheckbox}
        name={name}
        value={label}
        checked={checked}
        onChange={onClick}
      />
      <span className={styles.customCheckboxSpan}></span>
      <label htmlFor={id} className={styles.customCheckboxLabel}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
