// extracted by mini-css-extract-plugin
export var btn = "styles-module--btn--Lp8mI";
export var contentOneContainer = "styles-module--contentOneContainer--ZssXl";
export var contentPara = "styles-module--contentPara--dbZ52";
export var contentParaText = "styles-module--contentParaText--h4S5B";
export var contentSlider = "styles-module--contentSlider--0RHyg";
export var contentSliderContainer = "styles-module--contentSliderContainer--E10Lx";
export var contentSliderHeading = "styles-module--contentSliderHeading--sJgFq";
export var image = "styles-module--image--W46C0";
export var insuranceImage = "styles-module--insuranceImage--h3nqs";
export var mainHeading = "styles-module--mainHeading--DFlO3";