import React from 'react';
import Headline from '../../ui/Headline';
import Col from '../../ui/Grid/Col';
import Row from '../../ui/Grid/Row';
import DynamicImage from '../../ui/DynamicImage';
import Badge from '../../ui/Badge';
import Icon from '../../ui/Icon';
import * as styles from './styles.module.scss';
import classNames from 'classnames';
import Container from '../../ui/Grid/Container';

type PointsItem = {
  text: string;
};

type ContentTeaserItem = {
  desktopImage: string;
  mobileImage: string;
  badge1Line1: string;
  badge1Line2?: string;
  badge2Line1: string;
  badge2Line2?: string;
  title: string;
  headline: string;
  text: string;
  points: PointsItem[];
  btnText: string;
  btnTarget: string;
};

type ContentTeaserProps = {
  headline: string;
  text: string;
  items: ContentTeaserItem[];
};

const ContentTeaser = ({ headline, text, items = [] }: ContentTeaserProps) => {
  return (
    <Container additionalClass={styles.contentTeaser}>
      <Row additionalClass="mb-50">
        <Col mobile={10} tablet={10} tabletLandscape={10} desktop={6} centered>
          <div className={styles.textAbove}>
            <Headline additionalClass={styles.contentTeaserHeading} centered classLevel={1}>
              {headline}
            </Headline>
            <p className={styles.contentTeaserText}>{text}</p>
          </div>
        </Col>
      </Row>
      {items?.map((data, index) => (
        <div key={index} className={styles.item}>
          <div className={styles.mobileText}>
            <Headline additionalClass={styles.mobileContentHeading} classLevel={6}>
              <>
                <span className={styles.mobileContentNumber}>{index + 1} | </span> {data.title}
              </>
            </Headline>
            <Headline additionalClass={styles.mainHeading} classLevel={5}>
              {data.headline}
            </Headline>
          </div>
          <Row additionalClass={classNames(styles.row, { [styles.even]: (index + 1) % 2 == 0 })} nogap>
            <Col mobile={12} tablet={12} tabletLandscape={6} desktop={6} additionalClass={styles.imgCol}>
              <DynamicImage imageName={data.mobileImage} additionalClass={styles.mobileImage} />
              <DynamicImage imageName={data.desktopImage} additionalClass={styles.desktopImage} />
              <div className={styles.familyContainer}>
                {data.badge1Line1 && (
                  <Badge
                    headline="WENN"
                    firstHeadline={data.badge1Line1}
                    secondHeadline={data.badge1Line2}
                    position="top"
                  />
                )}
                {data.badge2Line1 && (
                  <Badge
                    headline="DANN"
                    firstHeadline={data.badge2Line1}
                    secondHeadline={data.badge2Line2}
                    position="bottom"
                  />
                )}
              </div>
            </Col>
            <Col mobile={12} tablet={12} tabletLandscape={6} desktop={6} additionalClass={styles.textCol}>
              <div className={styles.subHeading}>
                <Headline additionalClass={styles.contentHeading} classLevel={6}>
                  <>
                    <span className={styles.contentNumber}>{index + 1} | </span> {data.title}
                  </>
                </Headline>
              </div>
              <Headline additionalClass={styles.mainHeading} classLevel={5}>
                {data.headline}
              </Headline>
              <div className={styles.contentPara}>
                <p>{data.text}</p>
              </div>
              {data.points.map((point, index) => (
                <div className={styles.checkContent} key={index}>
                  <div>
                    <Icon name="check" additionalClass={styles.check} />
                  </div>
                  <p className={styles.pointOne}>{point.text}</p>
                </div>
              ))}
              <div className={styles.iconsContainer}>
                <a href={data.btnTarget} target="_blank" className={styles.btn}>
                  {data.btnText}
                </a>
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </Container>
  );
};

export default ContentTeaser;
