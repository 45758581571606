import React from 'react';
import Icon from '../../ui/Icon';
import Badge from '../../ui/Badge';
import DynamicImage from '../../ui/DynamicImage';
import Container from '../../ui/Grid/Container';
import * as styles from './styles.module.scss';

type PageHeaderProps = {
  video?: string;
  desktopImage?: string;
  mobileImage?: string;
  badge1Line1?: string;
  badge1Line2?: string;
  badge2Line1?: string;
  badge2Line2?: string;
};

const PageHeader = ({
  video,
  desktopImage,
  mobileImage,
  badge1Line1,
  badge1Line2,
  badge2Line1,
  badge2Line2,
}: PageHeaderProps) => {
  return (
    <Container additionalClass={styles.container}>
      {video ? (
        <video muted loop autoPlay className={styles.background}>
          <source type="video/mp4" src={video} />
        </video>
      ) : (
        <>
          <DynamicImage imageName={desktopImage} additionalClass={styles.desktopBackground} />
          <DynamicImage imageName={mobileImage} additionalClass={styles.mobileBackground} />
        </>
      )}
      <div className={styles.contentContainer}>
        {badge1Line1 && (
          <Badge
            headline="WENN"
            firstHeadline={badge1Line1}
            secondHeadline={badge1Line2}
            position="top"
            size="big"
            isHeader
          />
        )}
        {badge2Line1 && (
          <Badge
            headline="DANN"
            firstHeadline={badge2Line1}
            secondHeadline={badge2Line2}
            position="bottomMid"
            size="big"
            isHeader
          />
        )}
        <div className={styles.headerLogo}>
          <Icon name="logoClaim" />
        </div>
      </div>
    </Container>
  );
};

export default PageHeader;
