import React from 'react';
import Container from '../../ui/Grid/Container';
import Headline from '../../ui/Headline';
import * as styles from './styles.module.scss';

type KpiItem = {
  title: string;
  amount: string;
  text: string;
};

type ContentKpiProps = {
  headline: string;
  kpiItems: KpiItem[];
  btnText: string;
  btnTarget: string;
};

const Kpi = ({ headline, kpiItems, btnText, btnTarget }: ContentKpiProps) => {
  return (
    <Container additionalClass={styles.kpiWrap}>
      <div className={styles.kpiTitleWrap}>
        <Headline color="White" additionalClass={styles.heading} classLevel={1} level={3}>
          <div
            className={styles.kpiTitle}
            dangerouslySetInnerHTML={{
              __html: headline,
            }}
          />
        </Headline>
      </div>
      <div className={styles.kpiList}>
        {kpiItems.map((data: KpiItem, index: number) => (
          <div className={styles.kpiItem} key={index}>
            <p
              className={styles.itemTitle}
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
            <p className={styles.kpiAmount}>{data.amount}</p>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
      <a href={btnTarget} target="_blank" className={styles.btn}>
        {btnText}
      </a>
    </Container>
  );
};

export default Kpi;
