import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import Card from '../../../../ui/Card';
import Checkbox from '../../../../ui/Checkbox';
import DynamicImage from '../../../../ui/DynamicImage';
import Icon from '../../../../ui/Icon';
import Radio from '../../../../ui/Radio';
import questions from '../../../../../assets/data/questions.json';
import * as styles from './styles.module.scss';
import { FamilyCheckContext } from '../../../../../providers';

const tabNames = ['Lebensstatus', 'Job- und Wohnen', 'Lifestyle'];

type Props = {
  onNext?: VoidFunction;
};

const ChooseCheck: React.FC<Props> = ({ onNext = () => {} }) => {
  const { answerData, setAnswerData, currentQuestion, onNextQuestion, onPrevQuestion } = useContext(FamilyCheckContext);
  const [showImage, setShowImage] = useState(true);
  const images = ['familienstand', 'employed', 'transport'];

  const currentTab = useMemo(() => {
    switch (questions[currentQuestion].image) {
      case images[0]:
        return 1;
      case images[1]:
        return 2;
      case images[2]:
        return 3;
      default:
        return 1;
    }
  }, [currentQuestion]);

  const setMultiAnswer = (index: number, isChecked: boolean) => {
    setAnswerData((prev: any[]) => {
      if (isChecked) {
        if (!prev[currentQuestion]) prev[currentQuestion] = [{ index }];
        else prev[currentQuestion].push({ index });
      } else {
        const deletedId = prev[currentQuestion].findIndex((item: any) => item.index === index);
        if (deletedId >= -1) prev[currentQuestion].splice(deletedId, 1);
      }
      return [...prev];
    });
  };

  const setChildrenMultiAnswer = (index: number, cIndex: number, isChecked: boolean) => {
    setAnswerData((prev: any[]) => {
      const changeId = prev[currentQuestion].findIndex((item: any) => item.index === index);
      if (isChecked) {
        if (!prev[currentQuestion][changeId].children) prev[currentQuestion][changeId].children = [{ index: cIndex }];
        else prev[currentQuestion][changeId].children.push({ index: cIndex });
      } else {
        const deletedId = prev[currentQuestion][changeId].children.findIndex((item: any) => item.index === cIndex);
        if (deletedId >= -1) prev[currentQuestion][changeId].children.splice(deletedId, 1);
      }
      return [...prev];
    });
  };

  const setSingleAnswer = (index: number) => {
    setAnswerData((prev: any[]) => {
      prev[currentQuestion] = [{ index }];
      return [...prev];
    });
  };

  const setChildrenSingleAnswer = (index: number, cIndex: number) => {
    setAnswerData((prev: any[]) => {
      const changeId = prev[currentQuestion].findIndex((item: any) => item.index === index);
      prev[currentQuestion][changeId].children = [{ index: cIndex }];
      return [...prev];
    });
  };

  const isChildrenChecked = (answers: any[], index: number, cIndex: number) => {
    const id = answers.findIndex((item: any) => item.index === index);
    if (id <= -1) return false;
    return answers[id].children && answers[id].children.findIndex((item: any) => item.index === cIndex) > -1;
  };

  useEffect(() => {
    setShowImage(true);
    setTimeout(() => {
      setShowImage(false);
    }, 2000);
  }, [currentTab]);

  return (
    <div className={styles.wrapper}>
      <DynamicImage
        imageName={images[currentTab - 1]}
        additionalClass={classNames(styles.titleImage, styles.notMobile)}
        objectFit="contain"
        loading="eager"
      />

      <div className={classNames(styles.cardWrapper)}>
        <Card className={classNames(styles.card)} activeTab={currentTab}>
          <div className={styles.tabWrapper}>
            {tabNames.map((tabName, index) => (
              <div
                key={index}
                className={classNames(styles.tab, {
                  [styles.selected]: questions[currentQuestion].tabIndex === index,
                })}
              >
                {tabName}
              </div>
            ))}
          </div>
          <div className={styles.questionsWrapper}>
            <p className={styles.pageNum}>{`${currentQuestion + 1} / 10`}</p>
            <p className={styles.question}>{questions[currentQuestion].question}</p>
            <div className={styles.answerWrapper}>
              {questions[currentQuestion].answers.map((answer: any, index: number) => (
                <div key={index}>
                  {questions[currentQuestion].isMulti ? (
                    <Checkbox
                      label={answer.label}
                      onClick={(e: any) => {
                        setMultiAnswer(index, e.target.checked);
                      }}
                      checked={
                        answerData[currentQuestion] &&
                        answerData[currentQuestion].findIndex((item: any) => item.index === index) > -1
                      }
                    />
                  ) : (
                    <Radio
                      label={answer.label}
                      key={index}
                      name={questions[currentQuestion].question}
                      onClick={() => {
                        setSingleAnswer(index);
                        if (!answer.childAnswers) {
                          setTimeout(() => {
                            if (currentQuestion >= 9) {
                              onNext();
                            } else {
                              onNextQuestion();
                            }
                          }, 300);
                        }
                      }}
                      checked={
                        answerData[currentQuestion] &&
                        answerData[currentQuestion].findIndex((item: any) => item.index === index) > -1
                      }
                    />
                  )}
                  {answer.childAnswers &&
                    answerData[currentQuestion] &&
                    answerData[currentQuestion].findIndex((item: any) => item.index === index) > -1 && (
                      <div className={styles.childAnswers}>
                        {answer.childAnswers.map((child: any, cIndex: number) =>
                          answer.isMulti ? (
                            <Checkbox
                              label={child.label}
                              key={cIndex}
                              onClick={(e: any) => {
                                setChildrenMultiAnswer(index, cIndex, e.target.checked);
                              }}
                              checked={isChildrenChecked(answerData[currentQuestion], index, cIndex)}
                            />
                          ) : (
                            <Radio
                              label={child.label}
                              name={answer.label}
                              key={cIndex}
                              onClick={() => {
                                setChildrenSingleAnswer(index, cIndex);
                              }}
                              checked={isChildrenChecked(answerData[currentQuestion], index, cIndex)}
                            />
                          )
                        )}
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <button className={styles.button} disabled={currentQuestion <= 0} onClick={onPrevQuestion}>
              <Icon name="arrow" width={26} height={26} additionalClass="rotate-180" />
              <p>Zurück</p>
            </button>
            <button
              className={styles.button}
              disabled={questions[currentQuestion].required && !answerData[currentQuestion]}
              onClick={currentQuestion >= 9 ? onNext : onNextQuestion}
            >
              {currentQuestion >= 9 && <p>Zum Ergebnis</p>}
              {currentQuestion < 9 && <p>Weiter</p>}
              <Icon name="arrow" width={26} height={26} />
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ChooseCheck;
