// extracted by mini-css-extract-plugin
export var btn = "styles-module--btn--yFZP+";
export var check = "styles-module--check--j-1tc";
export var checkContent = "styles-module--checkContent--H64bz";
export var contentHeading = "styles-module--contentHeading--LMrCm";
export var contentNumber = "styles-module--contentNumber--O99Cn";
export var contentPara = "styles-module--contentPara--CxeBn";
export var contentTeaser = "styles-module--contentTeaser--LqKY6";
export var contentTeaserText = "styles-module--contentTeaserText--16Qrg";
export var desktopImage = "styles-module--desktopImage--p-lJN";
export var even = "styles-module--even--qeebI";
export var familyContainer = "styles-module--familyContainer--5CTJi";
export var iconsContainer = "styles-module--iconsContainer--0B3A+";
export var imgCol = "styles-module--imgCol--exAHA";
export var item = "styles-module--item--B9qBF";
export var mainHeading = "styles-module--mainHeading--mF9bC";
export var mobileContentHeading = "styles-module--mobileContentHeading--tEGdJ";
export var mobileContentNumber = "styles-module--mobileContentNumber--3hMnI";
export var mobileImage = "styles-module--mobileImage--YxkgA";
export var mobileText = "styles-module--mobileText--P1lG1";
export var pointOne = "styles-module--pointOne--l2yVR";
export var row = "styles-module--row--nEwIO";
export var subHeading = "styles-module--subHeading--L-0yt";
export var textAbove = "styles-module--textAbove---qEDg";
export var textCol = "styles-module--textCol--aRn-6";