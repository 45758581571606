import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import c from 'classnames';
import * as styles from './styles.module.scss';

type DynamicImageProps = {
  imageName?: string;
  additionalClass: string;
};

const DynamicImage = ({ imageName = '', additionalClass }: DynamicImageProps) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const match = data.images.edges.find(({ node }: { node: any }) => imageName === node.name);

  if (!match) return null;

  const {
    node: {
      name,
      childImageSharp: { gatsbyImageData },
    },
  } = match;
  return (
    <GatsbyImage image={gatsbyImageData} alt={name} className={c(styles.image, additionalClass)} />
  );
};

export default DynamicImage;
