// extracted by mini-css-extract-plugin
export var answerWrapper = "styles-module--answerWrapper--BVt2o";
export var button = "styles-module--button--M+hyp";
export var buttonWrapper = "styles-module--buttonWrapper--yl8DM";
export var card = "styles-module--card--kbktw";
export var cardWrapper = "styles-module--cardWrapper--G3x8g";
export var childAnswers = "styles-module--childAnswers--d0z4v";
export var notMobile = "styles-module--notMobile--KpAY7";
export var pageNum = "styles-module--pageNum--tBbdr";
export var question = "styles-module--question--EioMs";
export var questionsWrapper = "styles-module--questionsWrapper--ShIlv";
export var selected = "styles-module--selected--vp2pa";
export var slideFromLeft = "styles-module--slideFromLeft--WJJq9";
export var tab = "styles-module--tab---RI2Y";
export var tabWrapper = "styles-module--tabWrapper--sq4ri";
export var titleImage = "styles-module--titleImage--XPiHB";
export var wrapper = "styles-module--wrapper--xjP5P";