import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import CheckResult from './components/CheckResult';
import ChooseCheck from './components/ChooseCheck';
import Container from '../../ui/Grid/Container';
import DynamicImage from '../../ui/DynamicImage';
import { FamilyCheckProvider } from '../../../providers';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import Headline from '../../ui/Headline';
import * as styles from './styles.module.scss';

type FamilyCheckProps = {
  headline: string;
  text: string;
};

const FamilyCheck = ({ headline, text }: FamilyCheckProps) => {
  const [showQuestions, setShowQuestions] = useState(true);
  const [showResult, setShowResult] = useState(false);

  return (
    <FamilyCheckProvider>
      <Container>
        <Row additionalClass="mb-50">
          <Col mobile={10} tablet={10} tabletLandscape={10} desktop={6} centered>
            <div className={styles.textAbove}>
              <DynamicImage imageName="headline" additionalClass={styles.hlImage} />
              <p className={styles.contentTeaserText}>{text}</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container additionalClass={styles.wrapper}>
        <DynamicImage imageName="check_bg" additionalClass={styles.image}></DynamicImage>

        <CSSTransition
          in={showQuestions}
          timeout={{ enter: 600, exit: 0 }}
          onEnter={() => setShowResult(false)}
          onExited={() => setShowQuestions(false)}
          classNames={{
            enter: styles.questionsEnter,
            enterActive: styles.questionsEnterActive,
            exit: styles.questionsExit,
            exitActive: styles.questionsExitActive,
          }}
          unmountOnExit
        >
          <ChooseCheck onNext={() => setShowResult(true)} />
        </CSSTransition>

        <CSSTransition
          in={showResult}
          onEnter={() => setShowQuestions(false)}
          onExited={() => setShowResult(false)}
          timeout={{ enter: 600, exit: 0 }}
          classNames={{
            enter: styles.resultEnter,
            enterActive: styles.resultEnterActive,
            exit: styles.resultExit,
            exitActive: styles.resultExitActive,
          }}
          unmountOnExit
        >
          <CheckResult onRestart={() => setShowQuestions(true)} />
        </CSSTransition>
      </Container>
    </FamilyCheckProvider>
  );
};

export default FamilyCheck;
