import React from 'react';
import classNames from 'classnames';
import * as styles from './style.module.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  color?: 'primary' | 'secondary';
  type?: 'submit' | 'button' | 'reset' | undefined;
};

const Button = ({ className = '', label, disabled = false, onClick = () => {}, color = 'primary' }: Props) => {
  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className={classNames(
          className,
          styles.buttonStyle,
          {
            [styles.primary]: color === 'primary',
          },
          {
            [styles.secondary]: color === 'secondary',
          }
        )}
        onClick={onClick}
      >
        {label}
      </button>
    </>
  );
};

export default Button;
