import React, { useState, useEffect, createContext, ReactNode } from 'react';

import questions from '../assets/data/questions.json';

type ContextProps = {
  answerData: Array<any>;
  setAnswerData: React.Dispatch<React.SetStateAction<any[]>>;
  currentQuestion: number;
  onNextQuestion: VoidFunction;
  onPrevQuestion: VoidFunction;
  restart: VoidFunction;
};

type Props = {
  children: ReactNode;
};

export const FamilyCheckContext = createContext<ContextProps>({
  answerData: [],
  setAnswerData: () => {},
  currentQuestion: 0,
  onNextQuestion: () => {},
  onPrevQuestion: () => {},
  restart: () => {},
});

export const FamilyCheckProvider = ({ children }: Props) => {
  const [answerData, setAnswerData] = useState<any[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);

  useEffect(() => {
    setAnswerData(new Array(questions.length));
  }, []);

  const onNextQuestion = () => {
    if (currentQuestion === 9) return;
    setCurrentQuestion(prev => prev + 1);
  };

  const onPrevQuestion = () => {
    if (currentQuestion === 0) return;
    setCurrentQuestion(prev => prev - 1);
  };

  const restart = () => {
    setAnswerData([]);
    setCurrentQuestion(0);
  };

  return (
    <FamilyCheckContext.Provider
      value={{
        answerData,
        setAnswerData,
        currentQuestion,
        onNextQuestion,
        onPrevQuestion,
        restart,
      }}
    >
      {children}
    </FamilyCheckContext.Provider>
  );
};
